import bilAPI from '../api';

export const getPsychologyTestResults = ({
  page,
  page_size,
  test,
  grade,
  school,
  academic_term,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/account_test_result/`, {
          params: { page, page_size, test, grade, school, academic_term },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getPsychologyTestResultByUUID = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/account_test_result/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getClassSociometryTestResults = ({
  page,
  page_size,
  school_class,
  test,
  academic_year,
  academic_term,
  ordering,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/psychology/sociometry_data/`, {
          params: {
            page,
            page_size,
            test,
            school_class,
            academic_year,
            academic_term,
            ordering,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error');
    }
  });
};
